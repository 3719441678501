import React, { FunctionComponent } from "react"

export interface IconProps {
  fill?: string
  name: string
}

export const Icon: FunctionComponent<IconProps> = ({ fill = "#FFF", name }) => {
  switch (name) {
    case "arrow-down":
    default:
      return (
        <svg width="12" height="31">
          <path
            d="M6.584 28.514V.5h-1.23v27.953l-3.42-3.366H.5L6 30.5l5.5-5.413h-1.434z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      )
    case "arrow-right":
      return (
        <svg width="30" height="11">
          <path
            d="M28.014 4.916H0v1.23h27.953l-3.366 3.42V11L30 5.5 24.587 0v1.434z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      )

    case "arrow-left":
      return (
        <svg width="30" height="11">
          <path
            d="M1.986 6.084H30v-1.23H2.047l3.366-3.42V0L0 5.5 5.413 11V9.566z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      )

    case "instagram":
      return (
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" opacity=".5">
            <path d="M-10-10h40v40h-40z" />
            <path
              d="M10 1.802c2.67 0 2.986.01 4.04.058.976.045 1.505.208 1.858.344.466.182.8.399 1.15.748.35.35.566.684.748 1.15.136.353.3.882.344 1.857.048 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.04-.045.976-.208 1.505-.344 1.858-.182.466-.399.8-.748 1.15-.35.35-.684.566-1.15.748-.353.137-.882.3-1.857.344-1.054.048-1.37.058-4.041.058-2.67 0-2.987-.01-4.04-.058-.976-.045-1.505-.207-1.858-.344a3.098 3.098 0 0 1-1.15-.748 3.098 3.098 0 0 1-.748-1.15c-.137-.353-.3-.882-.344-1.857-.048-1.055-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.04.045-.976.207-1.505.344-1.858.182-.466.399-.8.748-1.15.35-.35.684-.566 1.15-.748.353-.136.882-.3 1.857-.344 1.055-.048 1.37-.058 4.041-.058M10 0c2.716 0 3.056.012 4.123.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 0 1 1.772 1.153 4.902 4.902 0 0 1 1.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123s-.012 3.056-.06 4.123c-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 0 1-1.153 1.772 4.902 4.902 0 0 1-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.049-1.407.06-4.123.06s-3.056-.011-4.123-.06c-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 0 1-1.772-1.153A4.902 4.902 0 0 1 .525 16.55c-.247-.636-.416-1.363-.465-2.427C.011 13.056 0 12.716 0 10s.011-3.056.06-4.123C.11 4.813.278 4.086.525 3.45a4.902 4.902 0 0 1 1.153-1.772A4.902 4.902 0 0 1 3.45.525C4.086.278 4.813.109 5.877.06 6.944.012 7.284 0 10 0zm.02 4.881a5.139 5.139 0 1 0 0 10.278 5.139 5.139 0 0 0 0-10.278zm0 8.475a3.336 3.336 0 1 1 0-6.672 3.336 3.336 0 0 1 0 6.672zm6.488-8.713a1.19 1.19 0 1 0-2.38 0 1.19 1.19 0 0 0 2.38 0z"
              fill={fill}
            />
          </g>
        </svg>
      )

    case "linkedin":
      return (
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path d="M-10-10h40v40h-40z" />
            <path
              d="M1.368 6.74H4.91v12.35H1.368V6.74zm1.681-1.546h-.025C1.74 5.194.909 4.25.909 3.054.91 1.835 1.765.91 3.074.91c1.307 0 2.111.923 2.137 2.142 0 1.196-.83 2.143-2.162 2.143zm16.042 13.897h-4.019v-6.392c0-1.673-.628-2.814-2.01-2.814-1.058 0-1.646.77-1.92 1.513-.102.266-.086.637-.086 1.01v6.683h-3.98s.05-11.323 0-12.352h3.98v1.939c.235-.848 1.507-2.058 3.538-2.058 2.518 0 4.497 1.778 4.497 5.602v6.869z"
              fillOpacity=".5"
              fill={fill}
            />
          </g>
        </svg>
      )

    case "medium":
      return (
        <svg width="20" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" opacity=".5">
            <path d="M-10-11h40v40h-40z" />
            <path
              d="M19.939 3.915a.073.073 0 0 1 .018.097l-6.063 9.852-4.359-7.083 3.474-5.645a.69.69 0 0 1 .878-.25l6.033 3.017.007.003c.005.002.008.006.012.01zM7.622 5.495l5.711 9.28-5.71-2.855V5.495zm6.57 9.71L20 5.765v11.312c0 .57-.496.784-1.108.478l-4.7-2.35zM6.63 3.925c.022.01.036.033.036.058v13.094c0 .419-.227.669-.55.669a.844.844 0 0 1-.373-.098l-5.19-2.595C.249 14.9 0 14.497 0 14.157V1.435C0 1.1.181.9.439.9c.092 0 .193.025.3.078L6.63 3.925z"
              fill={fill}
            />
          </g>
        </svg>
      )

    case "trello":
      return (
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="nonzero">
            <rect
              fill={fill}
              fillRule="nonzero"
              opacity=".5"
              width="20"
              height="20"
              rx="2"
            />
            <rect fill="#000" x="11.3" y="2.6" width="6" height="8.75" />
            <rect fill="#000" x="2.6" y="2.6" width="6" height="13.75" />
          </g>
        </svg>
      )

    case "strava":
      return (
        <svg width="15" height="20" xmlns="http://www.w3.org/2000/svg">
          <g fill={fill} opacity=".5" fillRule="evenodd">
            <path
              opacity=".6"
              d="M5.366 11.664L9.99 19.99l4.44-8.326h-2.775l-1.665 3.145-1.85-3.145z"
            />
            <path d="M5.92.007l5.736 11.657H0L5.92.007zm0 7.031l2.22 4.626H3.516l2.406-4.626z" />
          </g>
        </svg>
      )
  }
}
