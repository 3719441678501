import React, { FunctionComponent } from "react"
import classnames from "classnames"
import { Link } from "gatsby"

import { Navigation } from "./navigation"

export interface HeaderProps {
  theme: "light" | "dark"
  siteTitle: string
  navigation: { url: string; label: string }[]
}

export const Header: FunctionComponent<HeaderProps> = ({
  theme = "dark",
  siteTitle,
  navigation,
}) => {
  const headerClassNames = classnames("site-header", {
    [`site-header--${theme}`]: theme,
  })

  return (
    <header className={headerClassNames} id="site-header">
      <h1 className="site-logo">
        <Link to="/">{siteTitle}</Link>
      </h1>

      <a href="#main" className="u-visually-hidden  u-focusable">
        Skip to content
      </a>
      <a href="#site-footer" className="u-visually-hidden  u-focusable">
        Skip to footer
      </a>

      {/* {navigation && <Navigation navigation={navigation} />} */}
    </header>
  )
}
