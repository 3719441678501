import React, { FunctionComponent } from "react"

export interface ClientListProps {
  clients: { logo: string; name: string }[]
}

export const ClientList: FunctionComponent<ClientListProps> = ({ clients }) => {
  return (
    <div className="client-cards">
      {clients &&
        clients.map(client => {
          return (
            <figure className="client-card" key={client.name}>
              {/* @TODO: use gatsby-image */}
              <img src={client.logo} alt={client.name} />
            </figure>
          )
        })}
    </div>
  )
}
