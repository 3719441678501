import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const ContactSidebar = ({ links, contact }) => {
  return (
    <aside className="sidebar">
      <div className="sidebar__section">
        <h3 className="sidebar__heading">Find me online</h3>
        {contact.social && (
          <ul className="list--unstyled">
            {contact.social.map(social => {
              return (
                <li key={social.url}>
                  <OutboundLink
                    href={social.url}
                    rel="external  noopener  nofollow"
                    target="_blank"
                  >
                    {social.label}
                  </OutboundLink>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </aside>
  )
}
