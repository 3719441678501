import React, { FunctionComponent } from "react"
import classnames from "classnames"

export interface PageSectionProps {
  variant?: string
  id: string
  intro?: string
  subtitle?: string
  theme?: "light" | "mid" | "dark"
  title?: string
  titleClass?: string
  className?: string
}

export const PageSection: FunctionComponent<PageSectionProps> = ({
  children,
  variant,
  id,
  intro,
  subtitle,
  theme = "light",
  title,
  titleClass,
  className = "",
}) => {
  const sectionClassnames = classnames("page-section", className, {
    [`page-section--${theme}`]: theme,
    [`page-section--${variant}`]: variant,
  })

  return (
    <section id={id} className={sectionClassnames}>
      <div className="page-section__inner  u-clearfix">
        {title && (
          <h2 className={`page-section__title ${titleClass ? titleClass : ""}`}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
            {subtitle && (
              <span
                className={"page-section__subtitle"}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </h2>
        )}

        {intro && (
          <p
            className="page-section__intro"
            dangerouslySetInnerHTML={{
              __html: intro,
            }}
          />
        )}

        {children}
      </div>
    </section>
  )
}
