import React, { FunctionComponent } from "react"
import { useStaticQuery, graphql } from "gatsby"

import "../styles/index.css"

import { Header } from "./header"
import { Footer } from "./footer"

export interface LayoutProps {
  headerTheme?: "light" | "dark"
  children: React.ReactElement
}

export const Layout: FunctionComponent<LayoutProps> = ({
  headerTheme = "dark",
  children,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteTitle
          navigation {
            label
            url
          }
          contact {
            social {
              label
              url
            }
          }
        }
      }
    }
  `)

  const { siteTitle, navigation, contact } = data.site.siteMetadata
  const { social } = contact

  return (
    <>
      <Header
        theme={headerTheme}
        siteTitle={siteTitle}
        navigation={navigation}
      />

      <main id="main">{children}</main>

      {/* <Footer navigation={navigation} social={social} /> */}
    </>
  )
}
