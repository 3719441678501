import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

export interface SEOProps {
  title?: string
  description?: string
  meta?: []
  article?: boolean
}

export const SEO: FunctionComponent<SEOProps> = ({
  title,
  description,
  article = false,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteDescription
            url
            twitterUsername
          }
        }
      }
    `
  )

  const { siteDescription, siteTitle, url, twitterUsername } = site.siteMetadata
  const pageTitle = title ? `${title} :: ${siteTitle}` : siteTitle
  const metaDescription = description || siteDescription
  const { pathname } = useLocation()
  const seo = {
    title: pageTitle,
    description: metaDescription,
    url: `${url}${pathname}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={pageTitle}
    >
      <meta charSet="utf-8" />
      <meta name="description" content={seo.description} />

      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:type" content={`${article ? "article" : "website"}`} />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      <link rel="canonical" href={seo.url} />
    </Helmet>
  )
}
