import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export interface CaseStudySummaryProps {
  caseStudy: {
    slug?: string
    title?: string
    dates?: string
    location?: string
    role?: string
    technologies?: string[]
    website?: string
  }
}

export const CaseStudySummary: FunctionComponent<CaseStudySummaryProps> = ({
  caseStudy,
}) => {
  const {
    title,
    role,
    dates,
    slug,
    location,
    technologies,
    website,
  } = caseStudy

  const article = (
    <article className="case-study-summary">
      <div className="case-study__details">
        {title && <h2 className="case-study__title">{title}</h2>}
        <ul className="case-study__details-list  list--unstyled">
          {dates && (
            <li>
              Date <span>{dates}</span>
            </li>
          )}
          {role && (
            <li>
              Role <span>{role}</span>
            </li>
          )}
          {location && (
            <li>
              Location <span>{location}</span>
            </li>
          )}
          {technologies && (
            <li>
              Technologies: <span>{technologies.join(", ")}</span>
            </li>
          )}

          {website && (
            <li>
              Website
              <span>
                <OutboundLink
                  href={website}
                  rel="external  noopener  nofollow  noreferrer"
                  target="_blank"
                >
                  {website}
                </OutboundLink>
              </span>
            </li>
          )}
        </ul>
      </div>
    </article>
  )

  if (slug) {
    return (
      <Link to={`/work/${slug}`} className="case-study-summary__link">
        {article}
      </Link>
    )
  } else {
    return article
  }
}
